import React from 'react'
import styled from 'styled-components'
import { breakpoints, layout } from '../../theme'
import SalesPictureTitle from '../../components/SalesPictureTitle'
import EventBudgetSelector from '../../components/EventBudgetSelector'
import { useRedirect } from '../../hooks/useRedirect'
import { Button, Pulse } from '@monbanquet/crumble'
import { navigate } from '../../components/Link'
import { useWithDelay } from '../../hooks/useWithDelay'
import DemandeLayout from '../../components/DemandeLayout'
import { computeNextPageWithBaseUri } from '../../util/compute-next-page'

const BudgetPage = ({ location: { state } }) => {
  const redirect = useRedirect(!state || !state.next, '/demande/format')

  const submit = isSpecific => {
    const nextUrl = computeNextPageWithBaseUri('demande', 'budget', {
      isBudgetSpecific: isSpecific,
    })

    navigate(nextUrl, {
      state: { next: true },
    })
  }
  const [submitWithDelay, isDelayed] = useWithDelay(submit, 400)

  return (
    <StyledBudgetPage>
      <DemandeLayout>
        {redirect ? null : (
          <div>
            <SalesPictureTitle title="Merci de nous indiquer votre budget" />
            <form
              onSubmit={e => {
                e.preventDefault()
                submit()
              }}
            >
              <EventBudgetSelector
                onSelected={(_, isSpecific) => submitWithDelay(isSpecific)}
              />
              <div className="btn-bar">
                <Button id="previous" onClick={() => navigate(-1)}>
                  <div>&rarr;</div>
                  <span>&nbsp;Précédent</span>
                </Button>
                <Button id="next" type="submit">
                  {isDelayed ? <Pulse /> : <span>Suivant &rarr;</span>}
                </Button>
              </div>
            </form>
          </div>
        )}
      </DemandeLayout>
    </StyledBudgetPage>
  )
}

const StyledBudgetPage = styled.div`
  .page-content {
    max-width: 950px;
  }

  @media (max-width: ${breakpoints.mobile}px) {
    .page-content {
      margin-top: ${layout.navbarHeight.mobile}px;
    }
  }
`

export default BudgetPage
export { StyledBudgetPage }
