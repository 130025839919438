import React from 'react'
import styled from 'styled-components'

const ButtonGroup = ({ children, ...props }) => (
  <StyledButtonGroup {...props}>{children}</StyledButtonGroup>
)

const StyledButtonGroup = styled.ul`
  display: flex;

  > * {
    flex-grow: 1;
    flex-basis: 0;
    padding: 0;

    &:not(:last-child) {
      margin-right: 13px;
    }

    /* overriding the CheckBoxTile */
    max-height: 100px;
    min-width: 0 !important;
    max-width: none !important;
    label {
      padding-top: 20px;
      input {
        appearance: none;
      }
    }
  }
`

export default ButtonGroup
export { StyledButtonGroup }
