const fullDateFormatter = new Intl.DateTimeFormat('fr-FR', {
  weekday: 'long',
  day: 'numeric',
  month: 'long',
  year: 'numeric',
})

const timeFormatter = new Intl.DateTimeFormat('fr-FR', {
  hour: '2-digit',
  minute: '2-digit',
})

const priceFormatter = new Intl.NumberFormat('fr-FR', {
  style: 'currency',
  currency: 'EUR',
})

const formatFullDate = fullDateFormatter.format
const formatTime = timeFormatter.format
const formatPrice = priceFormatter.format
const formatShortPrice = value =>
  formatPrice(value)
    .replace(/,00/, '')
    .replace(/\s/, '')

export { formatPrice, formatFullDate, formatTime, formatShortPrice }
