import React, { useContext } from 'react'
import styled from 'styled-components'
import { sortBy } from 'lodash'
import { Button, StyledButton } from '@monbanquet/crumble'
import { breakpoints, colors } from '../theme'
import { formatShortPrice } from '../util/format'
import { EventContext } from './context/EventContext'
import ButtonGroup from './ButtonGroup'
import {
  defaultBudgets,
  defaultBudgetsPerPersonWithoutBanquetType,
  defaultBudgetTypes,
} from './context/EventContextDefaults'

const EventBudgetSelector = ({ onSelected, ...props }) => {
  const {
    state: { nbGuestsForm, zipcode, category, budget, budgetType },
    dispatch: dispatchEventCtx,
  } = useContext(EventContext)

  const selectBudget = (min, max) => {
    const bdg = { min, max, specific: null }
    dispatchEventCtx({
      type: 'UPDATE_BUDGET',
      payload: {
        budget: bdg,
      },
      analytics: { label: `MAX_${category}_${max}` },
    })
    onSelected(bdg)
  }
  const availableBudgets = sortBy(
    defaultBudgetsPerPersonWithoutBanquetType[category] || defaultBudgets,
  )
    .map((b, i, a) => ({
      min: b,
      max: a[i + 1],
    }))
    .filter(b => !!b.max)

  return (
    <StyledEventBudgetSelector {...props}>
      <div className="budget-type" data-cy="budget">
        {defaultBudgetTypes
          .map(bT => (
            <Button
              key={bT.value}
              className={`btn-link ${
                budgetType === bT.value ? 'selected' : ''
              }`}
              onClick={() =>
                dispatchEventCtx({
                  type: 'UPDATE',
                  payload: { budgetType: bT.value },
                })
              }
            >
              {bT.label}
            </Button>
          ))
          .reduce((r, a) => r.concat(a, '/'), [])
          .filter((_, i, a) => i < a.length - 1)}
      </div>

      <div className="budget-select">
        <ButtonGroup className="predefined-budgets">
          {availableBudgets &&
            availableBudgets.map(bdg => (
              <Button
                key={`budget-${zipcode}-${nbGuestsForm}-${category}-${bdg.min}`}
                className={`budget ${bdg.min === budget.min ? 'selected' : ''}`}
                onClick={() => selectBudget(bdg.min, bdg.max)}
              >
                {bdg.max >= 9999
                  ? `${formatShortPrice(
                      bdg.min * (budgetType === 'perPers' ? 1 : nbGuestsForm),
                    )} et +`
                  : `${formatShortPrice(
                      bdg.min * (budgetType === 'perPers' ? 1 : nbGuestsForm),
                    )} à ${formatShortPrice(
                      bdg.max * (budgetType === 'perPers' ? 1 : nbGuestsForm),
                    )}`}
              </Button>
            ))}
        </ButtonGroup>
        <Button
          className={`specific-budget ${!budget.min ? 'selected' : ''}`}
          onClick={() => {
            const bdg = { min: null, max: null, specific: null }
            dispatchEventCtx({
              type: 'UPDATE_BUDGET',
              payload: { budget: bdg },
              analytics: { label: 'Specific' },
            })
            onSelected(bdg, true)
          }}
        >
          {`J'ai un budget précis à respecter`}
        </Button>
      </div>
    </StyledEventBudgetSelector>
  )
}

const StyledEventBudgetSelector = styled.div`
  ${StyledButton} {
    color: ${colors.brand.black};
    text-transform: none;
    letter-spacing: unset;
    height: 66px;
    background-color: white;
    border: none;
    border-radius: 37px;
    font-size: 1.1rem;
  }

  .budget-select {
    display: flex;
    flex-grow: 1;

    ${StyledButton},
    .specific-budget {
      height: 66px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px 26px;
      font-size: 17px;
      margin-right: 10px;
      z-index: 1;
    }

    .specific-budget {
      font-weight: bold;
      justify-content: center;
    }

    .predefined-budgets {
      flex-grow: 1;
    }
  }

  .btn-link {
    background: none;
    border: none;
    outline: none;
    color: ${colors.text.light3};
    text-transform: none;
    letter-spacing: unset;
    font-size: 1.1rem;
    margin: 20px 0;
    padding: 0 5px;
    min-width: 0;

    &.selected {
      color: ${colors.text.semiLight};
      text-decoration: underline;
    }

    &:hover,
    &:focus {
      box-shadow: none;
      background: none;
      text-decoration: underline;
    }

    &:active {
      background: none;
    }
  }

  @media (max-width: 820px) {
    .budget-select {
      flex-direction: column;
      align-items: stretch;

      ${StyledButton},
      .specific-budget {
        height: 66px;
        display: flex;
        justify-content: center;
        padding: 15px 20px;
        font-size: 16px;
      }

      .predefined-budgets {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }

  @media (max-width: ${breakpoints.mobile}px) {
    .budget-select {
      ${StyledButton},
      .specific-budget {
        padding: 8px;
        font-size: 15px;
        margin-right: 4px;
      }

      .predefined-budgets {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }
`

export default EventBudgetSelector
export { StyledEventBudgetSelector }
